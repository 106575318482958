<template>
  <el-dialog
      title="转让班级"
      width="578px"
      top="50px"
      custom-class="transfer-dialog"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="onClose">
    <div class="transfer-dialog__line"></div>
    <div class="transfer-dialog__search">
      <el-input v-model="teacherName"
                class="transfer-dialog__input"
                placeholder="请输入教师姓名搜索"
                prefix-icon="el-icon-search"
                @input="onChangeTableData"
                size="large"
                clearable/>
      或
      <el-input v-model="teacherPhone"
                class="transfer-dialog__input"
                placeholder="请输入教师手机号搜索"
                prefix-icon="el-icon-search"
                @input="onChangeTableData"
                size="large"
                clearable/>
    </div>
    <el-radio-group v-model="selectedTeacherId">
      <el-radio v-for="item of tableData" :key="item.id" :label="item.id">
        <highlight-text-view font-size="17px" :title="item.name" :keyword="teacherName"/>
        <highlight-text-view font-size="17px" :title="item.phone" :keyword="teacherPhone"/>
      </el-radio>
    </el-radio-group>
    <div class="transfer-dialog__line"></div>
    <div class="transfer-dialog__footer">
      <div :class="{'confirm-disable':selectedTeacherId===''}"
           class="transfer-dialog__confirm" @click="authDialogVisible=true">确定
      </div>
      <div class="transfer-dialog__cancel" @click="onClose">取消</div>
    </div>

    <el-dialog
        title="手机验证"
        width="450px"
        custom-class="auth-dialog"
        :visible.sync="authDialogVisible"
        :close-on-click-modal="false"
        :before-close="()=>this.authDialogVisible=false"
        append-to-body>
      <p style="margin-bottom: 40px">确认将
        <span class="c-blue">{{ className }}</span>
        转让给
        <span class="c-blue">{{ name }}</span>
        ？为保证账户安全，请验证手机（{{ phone }}）
      </p>
      <el-input v-model="authCode" maxlength="6" placeholder="输入手机验证码">
        <div slot="suffix" :class="{'suffix-disable':timer}"
             class="auth-dialog__input-suffix"
             @click="validateAuthCode">{{ authCodeText }}
        </div>
      </el-input>
      <div :class="{'confirm-disable':!authCode}" class="auth-dialog__confirm" @click="onConfirm">提交验证</div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {getAuthCode, getTeacherList, transformClass} from "@/api/classes";
import HighlightTextView from '@/components/HighlightTextView'

export default {
  name: "dialogTransferClass",
  components: {HighlightTextView},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    classId: String,
    className: String,
  },
  data() {
    return {
      authDialogVisible: false,
      teacherName: '',
      teacherPhone: '',
      selectedTeacherId: '',
      teacherList: [],
      tableData: [],
      isSend: true,
      phone: '',
      authCode: '',
      timer: null,
      authCodeText: '发送验证码',
      countdown: 60,
    }
  },
  computed: {
    name() {
      return this.teacherList.find(item => item.id === this.selectedTeacherId)?.name
    }
  },
  created() {
    this.phone = sessionStorage.getItem('phone')
    this.loadData()
  },
  methods: {
    async loadData() {
      const schoolId = sessionStorage.getItem('schoolId')
      try {
        const res = await getTeacherList(schoolId)
        if (res.state === '11') {
          this.teacherList = this.tableData = res.data
        }
      } catch (e) {
      }
    },
    /**
     * 条件搜索
     */
    onChangeTableData() {
      this.tableData = this.teacherList.filter(item => {
        if (this.teacherName && this.teacherPhone) {
          return item.name.includes(this.teacherName) && item.phone.includes(this.teacherPhone)
        } else if (this.teacherName && !this.teacherPhone) {
          return item.name.includes(this.teacherName)
        } else if (!this.teacherName && this.teacherPhone) {
          return item.phone.includes(this.teacherPhone)
        } else {
          return item
        }
      })
    },
    async validateAuthCode() {
      if (!this.timer) {
        this.authCodeText = `${this.countdown}s`
        this.timer = setInterval(this._countDown, 1000)
        const res = await getAuthCode(this.phone)
        if (res.state !== '11') {
          clearInterval(this.timer)
          this.timer = null
          this.countdown = 60
          this.authCodeText = '发送验证码'
        }
      }
    },
    _countDown() {
      this.countdown--
      if (this.countdown > 0) {
        this.authCodeText = `${this.countdown}s`
      } else {
        clearInterval(this.timer)
        this.timer = null
        this.countdown = 60
        this.authCodeText = '重新发送验证码'
      }
    },
    async onConfirm() {
      if (!this.authCode) return
      try {
        const res = await transformClass(this.classId, this.selectedTeacherId, this.phone, this.authCode)
        if (res.state === '11') {
          this.$message.success('转让成功')
          this.authDialogVisible = false
          this.$emit('success')
          this.onClose()
        }
      } catch (e) {
      }
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.transfer-dialog {
  border-radius: 16px;

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .el-input__inner {
    color: #333333;
    font-size: 17px;
    border: 1px solid #C8C8C8;
    background-color: #f6f6f6;
  }

  .el-radio-group {
    height: 524px;
    overflow: auto;
  }

  .el-radio {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #F1F1F1;
    padding: 0 14px;
    margin: 0 28px;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .el-radio__inner {
    width: 20px;
    height: 20px;
  }

  .el-radio__label {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    line-height: 50px;
    color: #333333;
    font-size: 17px;
    font-weight: normal;
  }
}

.auth-dialog {
  border-radius: 16px;

  .el-dialog__header {
    padding-left: 28px;

    .el-dialog__title {
      font-size: 20px;
    }
  }

  .el-dialog__body {
    font-size: 18px;
    padding: 18px 28px;
  }

  .el-input__inner {
    height: 54px;
    border-radius: 27px;
    background-color: #f6f6f6;
    padding: 0 20px;
  }

}
</style>
<style lang="scss" scoped>
.transfer-dialog {

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    font-size: 16px;
    padding: 14px 20px;

  }

  &__input {
    width: 240px;
    font-size: 15px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
  }

  &__confirm {
    width: 150px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    border-radius: 4px;

    &:hover {
      background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
    }
  }

  &__cancel {
    width: 150px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #309af2;
    font-size: 15px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #309AF2;
    margin-left: 56px;

    &:hover {
      background-color: #F1F1F1;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: #F1F1F1;
  }
}

.auth-dialog {
  &__input-suffix {
    width: 98px;
    height: 34px;
    line-height: 34px;
    color: #FFFFFF;
    font-size: 14px;
    background: #309AF2;
    border-radius: 17px;
    margin: 10px;
    cursor: pointer;
  }

  .suffix-disable {
    cursor: not-allowed;
    background-color: #C8C8C8;
  }

  &__confirm {
    width: 394px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    border-radius: 4px;
    margin-top: 40px;

    &:hover {
      background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
    }
  }
}

.confirm-disable {
  cursor: not-allowed;
  background: #C0E0FB !important;
}

.c-blue {
  color: #39ACF5;
}
</style>
