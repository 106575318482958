<template>
  <el-popover
      v-model="visible"
      trigger="click"
      popper-class="detail-popover"
      @show="loadData">
    <h4>学生详情</h4>
    <div class="detail-popover__line"></div>
    <div class="detail-popover__item" style="align-items: center">
      <label>姓名：</label>
      <div class="detail-popover__item-name">
        <template v-if="isEdit">
          <el-input v-model="realName" maxlength="10" show-word-limit/>
          <el-button @click="onRename" type="text" class="detail-popover__item-save">保存</el-button>
        </template>
        <template v-else>
          {{ realName }}
          <img src="../../../assets/input.png" @click="isEdit=true" alt="edit"/>
        </template>
      </div>
    </div>
    <div class="detail-popover__item">
      <label>手机号：</label>
      <span v-if="phone">{{ phone }}</span>
      <span v-else class="col-FF3C30">未绑定</span>
    </div>
    <div class="detail-popover__item">
      <label>绑定家长：</label>
      <span v-if="bindParent===1">已绑定</span>
      <span class="col-FF3C30" v-else>未绑定</span>
    </div>
    <div class="detail-popover__item">
      <label>学习卡：</label>
      <div class="detail-popover__item-card">
        <span v-for="item of cardList" :key="item.code">{{ item.shortName || item.name }}</span>
      </div>
    </div>
    <template slot="reference">
      <slot/>
    </template>
  </el-popover>
</template>

<script>
import {getStudent, renameStudent} from "@/api/classes";

export default {
  name: "popoverDetails",
  props: {
    studentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      isEdit: false,
      realName: '',
      phone: '',
      bindParent: 0, // 0-否 1-已绑
      cardList: []
    }
  },
  methods: {
    async loadData() {
      try {
        const res = await getStudent(this.studentId)
        if (res.state === '11') {
          this.realName = res.data.realName
          this.phone = res.data.phone
          this.bindParent = Number(res.data.bindParent) || 0
          this.cardList = res.data.cardList
        }
      } catch (e) {
      }
    },
    async onRename() {
      try {
        const res = await renameStudent(this.studentId, this.realName)
        if (res.state === '11') {
          this.$message.success('修改成功')
          this.isEdit = false
          this.$emit('rename', this.realName)
        }
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss">
.detail-popover {
  min-width: 240px;
  padding: 18px 0 5px;

  .el-input__inner {
    min-width: 210px;
    height: 30px;
    line-height: 30px;
    background-color: #f6f6f6;
  }

  .el-input__count-inner {
    background: #f6f6f6 !important;
    padding-right: 0 !important;
  }
}
</style>
<style lang="scss" scoped>
.detail-popover {

  h4 {
    color: #999999;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 20px;
  }

  &__line {
    height: 1px;
    background-color: #F1F1F1;
    margin: 0 28px 14px;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 30px;
    margin-top: 10px;
    margin-left: 28px;
    margin-right: 10px;

    &-name {
      display: flex;
      align-items: center;
      font-size: 15px;

      img {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    &-save {
      color: #309af2;
      font-size: 13px;
      font-weight: normal;
      margin-left: 4px;
    }

    &-card {
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 14px;
      }
    }
  }
}
</style>
